exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-azure-vs-k-6-js": () => import("./../../../src/pages/azure-vs-k6.js" /* webpackChunkName: "component---src-pages-azure-vs-k-6-js" */),
  "component---src-pages-blazemeter-alternative-js": () => import("./../../../src/pages/blazemeter-alternative.js" /* webpackChunkName: "component---src-pages-blazemeter-alternative-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-data-processing-agreement-20200116-js": () => import("./../../../src/pages/data-processing-agreement-20200116.js" /* webpackChunkName: "component---src-pages-data-processing-agreement-20200116-js" */),
  "component---src-pages-data-processing-agreement-js": () => import("./../../../src/pages/data-processing-agreement.js" /* webpackChunkName: "component---src-pages-data-processing-agreement-js" */),
  "component---src-pages-data-sub-processors-20200128-js": () => import("./../../../src/pages/data-sub-processors-20200128.js" /* webpackChunkName: "component---src-pages-data-sub-processors-20200128-js" */),
  "component---src-pages-data-sub-processors-js": () => import("./../../../src/pages/data-sub-processors.js" /* webpackChunkName: "component---src-pages-data-sub-processors-js" */),
  "component---src-pages-fault-injection-testing-js": () => import("./../../../src/pages/fault-injection-testing.js" /* webpackChunkName: "component---src-pages-fault-injection-testing-js" */),
  "component---src-pages-hybrid-performance-testing-js": () => import("./../../../src/pages/hybrid-performance-testing.js" /* webpackChunkName: "component---src-pages-hybrid-performance-testing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-loadninja-alternative-js": () => import("./../../../src/pages/loadninja-alternative.js" /* webpackChunkName: "component---src-pages-loadninja-alternative-js" */),
  "component---src-pages-loadrunner-alternative-js": () => import("./../../../src/pages/loadrunner-alternative.js" /* webpackChunkName: "component---src-pages-loadrunner-alternative-js" */),
  "component---src-pages-modern-load-testing-for-engineering-teams-js": () => import("./../../../src/pages/modern-load-testing-for-engineering-teams.js" /* webpackChunkName: "component---src-pages-modern-load-testing-for-engineering-teams-js" */),
  "component---src-pages-open-source-js": () => import("./../../../src/pages/open-source.js" /* webpackChunkName: "component---src-pages-open-source-js" */),
  "component---src-pages-oss-vs-cloud-js": () => import("./../../../src/pages/oss-vs-cloud.js" /* webpackChunkName: "component---src-pages-oss-vs-cloud-js" */),
  "component---src-pages-our-beliefs-js": () => import("./../../../src/pages/our-beliefs.js" /* webpackChunkName: "component---src-pages-our-beliefs-js" */),
  "component---src-pages-privacy-policy-20200116-js": () => import("./../../../src/pages/privacy-policy-20200116.js" /* webpackChunkName: "component---src-pages-privacy-policy-20200116-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-professional-services-js": () => import("./../../../src/pages/professional-services.js" /* webpackChunkName: "component---src-pages-professional-services-js" */),
  "component---src-pages-resource-library-js": () => import("./../../../src/pages/resource-library.js" /* webpackChunkName: "component---src-pages-resource-library-js" */),
  "component---src-pages-security-policy-20200116-js": () => import("./../../../src/pages/security-policy-20200116.js" /* webpackChunkName: "component---src-pages-security-policy-20200116-js" */),
  "component---src-pages-services-agreement-20200121-js": () => import("./../../../src/pages/services-agreement-20200121.js" /* webpackChunkName: "component---src-pages-services-agreement-20200121-js" */),
  "component---src-pages-services-agreement-20201127-js": () => import("./../../../src/pages/services-agreement-20201127.js" /* webpackChunkName: "component---src-pages-services-agreement-20201127-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-unit-testing-for-performance-js": () => import("./../../../src/pages/unit-testing-for-performance.js" /* webpackChunkName: "component---src-pages-unit-testing-for-performance-js" */),
  "component---src-pages-website-stress-testing-js": () => import("./../../../src/pages/website-stress-testing.js" /* webpackChunkName: "component---src-pages-website-stress-testing-js" */),
  "component---src-pages-why-your-organization-should-perform-load-testing-js": () => import("./../../../src/pages/why-your-organization-should-perform-load-testing.js" /* webpackChunkName: "component---src-pages-why-your-organization-should-perform-load-testing-js" */)
}

