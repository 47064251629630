/* eslint-disable import/no-extraneous-dependencies */
const queryString = require('query-string');
const { Cookies } = require('react-cookie-banner');

const cookies = new Cookies();

// The logic is implemented on the `onClientEntry` hook assuming that
// only external services use UTM params to landing pages in the main site.
// if we start using UTM internally, the `onClientEntry` hook should be replaced.
exports.onClientEntry = () => {
  const { search } = window.location;
  if (search) {
    const { utm_campaign, utm_source, utm_medium } = queryString.parse(search);

    if (utm_campaign && utm_source && utm_medium) {
      // we need to setup `domain` in  order the webapp can read the cookie
      cookies.set(
        'k6_utm',
        { utm_campaign, utm_source, utm_medium },
        { path: '/', domain: '.k6.io', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) }
      );
    }
  }
};
