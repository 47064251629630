// eslint-disable-next-line import/no-unresolved
import { globalHistory } from '@reach/router';

require('./src/styles/styles.scss');

export const onInitialClientRender = () => {
  /**
   * This is a workaround for a bug in Gatsby
   *
   * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
   */
  globalHistory._onTransitionComplete();
};
